import styled from '@emotion/styled'
// import dayjs from 'dayjs'
import React from 'react'

import { theme } from '../media/font'

// const SEC_IN_MS = 1000
// const MIN_IN_MS = 60 * SEC_IN_MS
// const HR_IN_MS = 60 * MIN_IN_MS
// const DAY_IN_MS = HR_IN_MS * 24

// const endDate = dayjs('2019-11-15T09:00:00+01:00')

// const Number = styled('span')`
//   width: 2.5em;
//   display: inline-block;
// `

const Big = styled('span')`
  display: block;
  letter-spacing: 0.25em;
  color: ${theme.primaryLight};
  text-shadow: 0.05em 0.05em white;
  line-height: 1.3;
`
const Small = styled('span')`
  display: block;
  letter-spacing: 0.3em;
  font-size: 0.25em;
  line-height: 1.8;
`

const Title = () => {
  // const [diffMs, setDiffMs] = useState(endDate.diff(dayjs()))
  // const [daysToGo, setDaysToGo] = useState(0)
  // const [hrsToGo, setHrsToGo] = useState(0)
  // const [minsToGo, setMinsToGo] = useState(0)
  // const [secsToGo, setSecsToGo] = useState(0)

  // useEffect(() => {
  //   const interval = setInterval(function() {
  //     setDiffMs(endDate.diff(dayjs()))
  //   }, 1000)

  //   return function cleanup() {
  //     clearInterval(interval)
  //   }
  // }, [])

  // useEffect(() => {
  //   let remaindingMs = 0
  //   const daysToGo = Math.floor(diffMs / DAY_IN_MS)
  //   remaindingMs = diffMs % DAY_IN_MS
  //   const hrsToGo = Math.floor(remaindingMs / HR_IN_MS)
  //   remaindingMs = remaindingMs % HR_IN_MS
  //   const minsToGo = Math.floor(remaindingMs / MIN_IN_MS)
  //   remaindingMs = remaindingMs % MIN_IN_MS
  //   const secsToGo = Math.floor(remaindingMs / SEC_IN_MS)
  //   setDaysToGo(daysToGo)
  //   setHrsToGo(hrsToGo)
  //   setMinsToGo(minsToGo)
  //   setSecsToGo(secsToGo)
  // }, [diffMs])

  return (
    <h1>
      {/* <Small>Program reveal in</Small>
      <Big>
        {daysToGo} day{daysToGo > 1 && 's'}
      </Big>
      <Small
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          alignItems: 'center',
        }}
      >
        <span>
          <Number>
            {hrsToGo < 10 && 0}
            {hrsToGo}
          </Number>
          hour{hrsToGo > 1 && 's'}
          <br />
          <Number>
            {minsToGo < 10 && 0}
            {minsToGo}
          </Number>
          minute{minsToGo > 1 && 's'}
          <br />
          <Number>
            {secsToGo < 10 && 0}
            {secsToGo}
          </Number>
          second{secsToGo > 1 && 's'}
        </span>
      </Small> */}
      <Small>Christmas</Small>
      <Big>Party</Big>
      <Small>Leo Innovation Lab</Small>
      <Small>06.12.19&emsp;10:00-??</Small>
    </h1>
  )
}

export { Title }
