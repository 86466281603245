import styled from '@emotion/styled'
import React from 'react'

// import { font, theme } from '../media/font'
import { EventItem } from './eventItem'

const Article = styled('article')`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 1em;
  padding-bottom: 4em;
`

const Ul = styled('ul')`
  padding-left: 0;
  list-style: none;
`

// const Link = styled('a')`
//   color: ${theme.primary};
//   background-color: white;
//   text-decoration: none;
//   border-radius: 999px;
//   font-weight: ${font.weight.bold};
//   padding: var(--font-size);
//   font-size: calc(var(--font-size) * var(--bold-factor));
//   margin-bottom: var(--font-size);
//   display: block;
//   text-align: center;
//   letter-spacing: 0.05em;
//   text-transform: uppercase;
//   box-shadow: 0.2em 0.2em ${theme.primaryLighter};
// `

const Invitation = () => (
  <Article>
    <h2>Winter Wonderland Ball</h2>
    <p>
      Welcome to the grand celebration of yet another year of awesomeness in the one and
      only ILab.
    </p>
    <h3>Program</h3>
    <Ul>
      <EventItem
        icons={['chef', 'eating']}
        startTime="10:00"
        endTime="11:30"
        copy="Brunch @ILab"
      />
      <EventItem
        icons={['bus', 'mountain']}
        startTime="11:30"
        endTime="12:00"
        copy="Busses to CopenHill"
      />
      <EventItem
        icons={['boot', 'helmet']}
        startTime="12:00"
        endTime="12:30"
        copy="Equipment pickup"
      />
      <EventItem
        icons={['skiing', 'sledding']}
        startTime="12:30"
        endTime="14:30"
        copy="Skiing/snowboarding/sledding @CopenHill"
      />
      <EventItem
        icons={['beer', 'afterski']}
        startTime="14:30"
        endTime="15:30"
        copy="Bar @CopenHill"
      />
      <EventItem
        icons={['bus2', 'lion']}
        startTime="15:30"
        endTime="16:00"
        copy="Return to ILab"
      />
      <EventItem
        icons={['dress', 'makeup']}
        startTime="16:00"
        endTime="18:00"
        copy="Get dressed"
      />
      <EventItem
        icons={['dance', 'dinner']}
        startTime="18:00"
        endTime="??"
        copy="Dinner &amp; party @ILab"
      />
    </Ul>

    <h3>Expect</h3>
    <p>Sparkles &amp; snowflakes.</p>
    <h3>Nighttime Dress Code</h3>
    <p>
      Smart &amp; classy. Think white. Think ice/dark blue. Think glitter &mdash; lots of
      it...
    </p>
    <p>
      Best outfits will be awarded. Please raise your game and expect tough competition.
    </p>
    <h3>Questions etc.</h3>
    <p>...may be directed to your Wonderlanders:</p>
    <p>Sara, Julie, Henri, Ida, Charlotte L &amp; Andreas L</p>
  </Article>
)

export { Invitation }
