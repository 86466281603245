import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { theme } from '../media/font'
// import p1 from '../media/image/p1.png'
// import p2 from '../media/image/p2.png'
// import p3 from '../media/image/p3.png'
// import p4 from '../media/image/p4.png'
import p5 from '../media/image/p5.png'
import p6 from '../media/image/p6.png'
import p7 from '../media/image/p7.png'
import p8 from '../media/image/p8.png'
import skiing from '../media/video/skiing.mp4'
import { Snowflake } from './Snowflake'
import { Title } from './Title'

const LoadAnimationDuration = 2000

const Container = styled('div')`
  --offset: 0;
  width: 100vw;
  height: 75vw;
  min-height: 500px;
  position: relative;
  z-index: -1;
`

const ImageWrap = styled('div')<{ zIndex: number }>`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate3D(-50%, var(--offset), 0);
  z-index: ${props => props.zIndex};
  ${props =>
    props.zIndex === 0 &&
    `
    box-shadow: 0 400vw 0 400vw ${theme.primary}, inset 0 0 5vw ${theme.primary};
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -10vw;
      right: -10vw;
      height: 5vmin;
      background: linear-gradient(${theme.primaryTransparent}, ${theme.primary});
    }`}
`

const Image = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoWrap = styled('div')`
  position: relative;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.primaryLight};
    mix-blend-mode: hard-light;
  }
`

const Video = styled('video')`
  width: 100%;
  height: 99%;
  object-fit: cover;
  filter: grayscale(100%) brightness(1.7);
`

type LoaderProps = {
  doneLoading: boolean
}

const Loader = styled('div')<LoaderProps>`
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  transform: translateY(${props => (props.doneLoading ? '0' : '-25%')});
  opacity: ${props => (props.doneLoading ? 1 : 0)};
  transition: 1s ease;
  transition-delay: var(--delay);
`

const LoadOverlay = styled('div')<{ loaded: boolean }>`
  pointer-events: none;
  &::before,
  &::after {
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: ${theme.primary};
    content: '';
    z-index: 1;
    transition: ${LoadAnimationDuration / 1000}s ease;
    transition-delay: calc(var(--delay) + 0.3s);
  }
  &::before {
    right: 50%;
    left: 0;
    transform: translateX(${props => (props.loaded ? '-100%' : '0')});
  }
  &::after {
    right: 0;
    left: 50%;
    transform: translateX(${props => (props.loaded ? '100%' : '0')});
  }
`

const Header = () => {
  const [scrollY, setScrollY] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const [allimagesLoaded, setAllimagesLoaded] = useState(false)
  const [loadedTime, setLoadedTime] = useState(0)
  const [delay, setDelay] = useState(0)

  const handleImageLoaded = () => {
    setImagesLoaded(imagesLoaded + 1)
  }

  const getTime = () => new Date().getTime()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const { innerHeight, scrollY } = window
      const offset = scrollY < innerHeight * 2 ? scrollY : innerHeight
      setScrollY(offset)
    })
    setLoadedTime(getTime())
  }, [])

  useEffect(() => {
    if (imagesLoaded === 2) {
      setDelay((getTime() - loadedTime) / 1000)
      setAllimagesLoaded(true)
    }
  }, [imagesLoaded, loadedTime])

  const snowFlakes = Array.from(Array(60).keys())
  return (
    <>
      <LoadOverlay
        loaded={allimagesLoaded}
        style={{ '--delay': `${delay}s` } as React.CSSProperties}
      >
        <Loader doneLoading={!allimagesLoaded}>
          <h1>Loading...</h1>
        </Loader>
      </LoadOverlay>
      <Container>
        {snowFlakes.map(key => (
          <Snowflake key={key} />
        ))}
        <ImageWrap
          zIndex={-7}
          style={{ '--offset': `${scrollY / 1.1}px` } as React.CSSProperties}
        >
          <VideoWrap>
            <Video src={skiing} muted autoPlay loop playsInline />
          </VideoWrap>

          {/* <Image src={p1} onLoad={handleImageLoaded} /> */}
        </ImageWrap>
        {/*
        <ImageWrap
          zIndex={-6}
          style={{ '--offset': `${scrollY / 1.2}px` } as React.CSSProperties}
        >
          <Image src={p2} onLoad={handleImageLoaded} />
        </ImageWrap> */}
        <ImageWrap
          zIndex={-5}
          style={{ '--offset': `${scrollY / 1.3}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Title />
          {/* <Image src={p3} onLoad={handleImageLoaded} /> */}
        </ImageWrap>
        {/* <ImageWrap
          zIndex={-4}
          style={{ '--offset': `${scrollY / 1.4}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Image src={p4} onLoad={handleImageLoaded} />
        </ImageWrap> */}
        <ImageWrap
          zIndex={-3}
          style={{ '--offset': `${scrollY / 1.5}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Image src={p5} onLoad={handleImageLoaded} />
        </ImageWrap>
        <ImageWrap
          zIndex={-2}
          style={{ '--offset': `${scrollY / 1.6}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Image src={p6} onLoad={handleImageLoaded} />
        </ImageWrap>
        <ImageWrap
          zIndex={-1}
          style={{ '--offset': `${scrollY / 1.4}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Image src={p7} onLoad={handleImageLoaded} />
        </ImageWrap>
        <ImageWrap
          zIndex={0}
          style={{ '--offset': `${scrollY / 8}px` } as React.CSSProperties}
          onLoad={handleImageLoaded}
        >
          <Image src={p8} onLoad={handleImageLoaded} />
        </ImageWrap>
      </Container>
    </>
  )
}

export { Header }
