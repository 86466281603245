import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import * as React from 'react'

import { randomNumber } from '../utillities/randomNumber'
import { SnowFlakeIcon, SnowFlakeIconNames } from './SnowFlakeIcons'

const fallingAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) translateY(var(--animation-start));
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) translateY(var(--animation-end)) scale(0.75);
    opacity: 0.5;
  }
`

const Flake = styled('div')`
  --delay: 0s;
  --left: 0%;
  --depth: 0;
  --animation-start: -25px;
  --animation-end: 525px;
  --animation-base-speed: 3s;
  --random-speed: 3s;
  position: absolute;
  top: 0;
  left: var(--left);
  width: calc(1rem + 10vw / var(--depth));
  height: calc(1rem + 10vw / var(--depth));
  will-change: transform, opacity;
  animation-name: ${fallingAnimation};
  animation-duration: calc(
    var(--animation-base-speed) + 5s * var(--depth) + var(--random-speed)
  );
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.8, 1); /* Slight ease-out */
  animation-delay: var(--delay);
  z-index: calc(-1 * var(--depth) + 1);

  @media screen and (min-width: 833px) {
    --animation-start: -5vw;
    --animation-end: 80vw;
    --animation-base-speed: 5s;
  }
`

const Snowflake = React.memo(() => {
  const left = randomNumber(0, 10000) / 100
  const depth = randomNumber(1, 8)
  const delay = randomNumber(-3000, 0) / 100
  const flakeNumber = randomNumber(1, 4)
  const randomSpeed = randomNumber(0, 300) / 10
  const name = `snowflake${flakeNumber}` as SnowFlakeIconNames

  return (
    <Flake
      style={
        {
          '--delay': `${delay}s`,
          '--random-speed': `${randomSpeed}s`,
          '--depth': depth,
          '--left': `${left}%`,
        } as React.CSSProperties
      }
    >
      <SnowFlakeIcon name={name} />
    </Flake>
  )
})

export { Snowflake }
