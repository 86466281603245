import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { Icon, IconName } from './Icons'

const IconAnimation = keyframes`
0%, 39% {
  opacity: 1;
  transform: rotate(0deg);
}
43% {
  opacity: 1;
  transform: rotate(-4deg);
}
45% {
  opacity: 1;
  transform: rotate(0deg);
}
55%, 100% {
  opacity: 0;
  transform: rotate(20deg) scale(0.8);
}
`

const Container = styled('li')`
  --iconSize: 48px;
  --duration: 5s;
  --delay: 0s;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: var(--font-size);
  }

  :nth-of-type(1) {
    --delay: calc(1 * var(--duration) / 50);
  }

  :nth-of-type(2) {
    --delay: calc(2 * var(--duration) / 50);
  }

  :nth-of-type(3) {
    --delay: calc(3 * var(--duration) / 50);
  }

  :nth-of-type(4) {
    --delay: calc(4 * var(--duration) / 50);
  }

  :nth-of-type(5) {
    --delay: calc(5 * var(--duration) / 50);
  }

  :nth-of-type(6) {
    --delay: calc(6 * var(--duration) / 50);
  }

  :nth-of-type(7) {
    --delay: calc(7 * var(--duration) / 50);
  }

  :nth-of-type(8) {
    --delay: calc(8 * var(--duration) / 50);
  }

  :nth-of-type(9) {
    --delay: calc(9 * var(--duration) / 50);
  }

  & > *:not(:last-child) {
    margin-right: var(--font-size);
  }

  @media screen and (min-width: 500px) {
    --iconSize: 64px;
  }
`

const Icons = styled('div')`
  flex-basis: var(--iconSize);
  height: var(--iconSize);
  flex-shrink: 0;
  position: relative;
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: var(--duration) linear var(--delay) ${IconAnimation} infinite alternate
      both;
    &:nth-of-type(2) {
      animation-direction: alternate-reverse;
    }
  }
`

const Time = styled('div')`
  flex-basis: 2.6em;
  flex-shrink: 0;
`

const Copy = styled('div')``

type Props = {
  icons: [IconName, IconName]
  startTime: string
  endTime: string
  copy: string
}

const EventItem = ({ icons, startTime, endTime, copy }: Props) => {
  const formattedStartTime = startTime.replace(/:/, '\u2009:\u2009')
  const formattedEndTime = endTime.replace(/:/, '\u2009:\u2009')
  return (
    <Container>
      <Icons>
        <Icon name={icons[0]} />
        <Icon name={icons[1]} />
      </Icons>
      <Time>
        <div>{formattedStartTime}</div>
        <div>{formattedEndTime}</div>
      </Time>
      <Copy>{copy}</Copy>
    </Container>
  )
}

export { EventItem }
