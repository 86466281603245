import React from 'react'

import { Header } from './components/Header'
import { IconSprite } from './components/Icons'
import { Invitation } from './components/Invitation'
import { SnowFlakeIconSprite } from './components/SnowFlakeIcons'
import { Fonts } from './media/font'

const App: React.FC = () => {
  return (
    <>
      <Fonts />
      <Header />
      <Invitation />
      <SnowFlakeIconSprite />
      <IconSprite />
    </>
  )
}

export default App
