import { css, Global } from '@emotion/core'
import React from 'react'

import BigJohnPROBoldWoff from './BigJohnPRO-Bold.woff'
import BigJohnPROBoldWoff2 from './BigJohnPRO-Bold.woff2'
import BigJohnPRORegularWoff from './BigJohnPRO-Regular.woff'
import BigJohnPRORegularWoff2 from './BigJohnPRO-Regular.woff2'

export const font = {
  family: 'BigJohn',
  weight: {
    bold: 700,
    regular: 400,
  },
}

export const theme = {
  primaryDark: 'hsl(234, 59%, 15%)',
  primary: 'hsl(234, 59%, 23.5%)',
  primaryLight: 'hsl(234, 59%, 40%)',
  primaryLighter: 'hsl(234, 59%, 50%)',
  primaryTransparent: 'hsla(234, 59%, 23.5%, 0)',
}

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: '${font.family}';
        font-weight: ${font.weight.bold};
        src: url('${BigJohnPROBoldWoff2}') format('woff2'), url('${BigJohnPROBoldWoff}') format('woff');
      }
      @font-face {
        font-family: '${font.family}';
        font-weight: ${font.weight.regular};
        src: url('${BigJohnPRORegularWoff2}') format('woff2'), url('${BigJohnPRORegularWoff}') format('woff');
      }
      :root {
        --font-size: 18px;
        --font-scale: 1.4;
        --bold-factor: 1.1;
        @media screen and (min-width: 700px) {
          --font-size: 24px;
          --font-scale: 1.6;
        }
      }
      html {
        box-sizing: border-box;
        color: #ffffff;
        line-height: var(--font-size * 1.5);
        font-family: '${font.family}';
        font-size: var(--font-size);
        background-color: ${theme.primary};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      *, *::before, *::after {
        box-sizing: inherit;
      }
      ::selection {
        color: ${theme.primary};
        background-color: white;
      }
      body {
        margin: 0;
        overflow-x: hidden;
      }
      #root {
        overflow: hidden;
      }
      h1 {
        text-transform: uppercase;
        text-align: center;
        font-size: calc(var(--font-size) * var(--font-scale) * var(--font-scale) * var(--bold-factor));
        margin-top: 2em;
        @media screen and (min-width: 700px) {
          font-size: 5vw;
          margin-top: 9vw;
        }
      }
      h2 {
        font-size: calc(var(--font-size) * var(--font-scale) * var(--bold-factor));
        margin-top: calc(var(--font-size) * 3);
        margin-bottom: calc(var(--font-size) * 1.5);
      }
      h3 {
        font-size: calc(var(--font-size) * var(--bold-factor));
        margin-top: calc(var(--font-size) * 2);
        margin-bottom: calc(var(--font-size) * 0.5);
      }
      p, ul {
        font-size: var(--font-size);
        margin-top: 0;
        margin-bottom: calc(var(--font-size) * 0.5);
        letter-spacing: 0.3px;
      }
    `}
  />
)

export { Fonts }
